<template>
    <div>
      
        <img src="@/assets/img/home/Cumpleaños/21.jpg" class="w-100 h-100 img-background" alt="Novios abrazados">
       
      <HomeCard v-for="(card, index) in cards" :datos="card" :key="index" :id="'home-card-'+index" class="mvh-lg-100" />
    </div>
  </template>
  <script>
    
    import HomeCard from '@/components/home/Card.vue'
    import Img1 from '@/assets/img/home/Cumpleaños/22.jpg'
    import Img1_2 from '@/assets/img/home/2-1.png'
    import Img1_3 from '@/assets/img/home/2-2.png'
  
    import Img2 from '@/assets/img/home/Cumpleaños/23.jpg'
    import Img2_2 from '@/assets/img/home/3-1.png'
  
    import Img3 from '@/assets/img/home/Cumpleaños/24.jpg'
    import Img3_2 from '@/assets/img/home/4-1.png'
    
    export default {
      name: 'Home',
      components: {
        
        HomeCard
      },
      data(){
        return {
          cards: [{
            title: 'Celebra con Nosotros tu Cumpleaños',
            descripcion: " En este día de luces y colores brillantes, celebramos el regalo de tu existencia radiante. Con cada vela que se enciende en tu pastel, brilla el amor que en nuestros corazones has sellado. ¡Feliz cumpleaños, querido/a! Hoy es tu día, una oportunidad para reír, soñar y sonreír con alegría. En cada paso que has dado, en cada sueño que has tejido, has dejado una huella de amor que no ha sido desvanecida. Que este nuevo año que comienza sea como una flor, que florece con esperanza, amor y un dulce sabor. Que tus risas sean como música en el viento que susurra, llenando cada día con alegría, sin medida alguna. Que tus sueños alcancen las estrellas más altas, que tus días estén llenos de aventuras nuevas y gratas. Que cada momento sea un tesoro en tu camino, recordándote siempre cuánto te queremos, ¡cuánto te admiramos! Así que sopla las velas, cierra los ojos y pide un deseo, que el universo escucha y en tu vida se hace presente, te lo prometo. Porque hoy celebramos no solo un año más, sino a ti, un ser maravilloso, un regalo del cielo, un motivo de amor sin fin. ¡Feliz cumpleaños! Que este día sea solo el comienzo, de un año lleno de amor, felicidad y sueños que cumplir en tu destino.",
            img1: Img1,
            img2: Img1_2,
            img3: Img1_3,
            bg: 'bg-primary text-white',
            align: 'end',
            url: 'como-funciona.index',
            urlname: 'Como funciona',
            urlClass: 'btn-light',
            hide: true
          },
          {
            title: 'Aprovecha cada Momento',
            descripcion: '¡Celebremos la maravilla de un nuevo año de vida! Un cumpleaños no es solo un día en el calendario, es un recordatorio de todo lo que nos hace únicos, especiales y valiosos. Es un momento para reflexionar sobre el pasado, celebrar el presente y anticipar el futuro con esperanza y emoción. Es un día lleno de gratitud por las experiencias vividas, los desafíos superados y los sueños perseguidos. Cada año que pasa nos trae nuevas lecciones, nuevas oportunidades y nuevos horizontes por explorar. Es un día para honrar nuestras conexiones, nuestros afectos y nuestras relaciones. Es un recordatorio de la importancia de la familia, los amigos y aquellos que nos rodean, quienes hacen que cada día valga la pena. Es un día para celebrar la vida en toda su belleza y complejidad. Es un momento para apreciar la alegría de estar vivos, la magia de cada momento y la promesa de un mañana lleno de posibilidades. Así que hoy, en tu cumpleaños, celebremos juntos el regalo más preciado de todos: la vida. ¡Brindemos por ti, por tus sueños y por todo lo que está por venir! ¡Feliz cumpleaños! ',
            img1: Img2,
            img2: Img2_2,
            bg: 'bg-secondary text-white',
            align: 'start',
            hide: true,
          },
          {
            title: 'Comparte',
            descripcion: ' Queridos amigos y familiares, Quiero expresar mi más profundo agradecimiento a todos ustedes por hacer de mi cumpleaños un día tan especial y memorable. Cada mensaje, llamada, regalo y muestra de cariño ha llenado mi corazón de alegría y gratitud. Me siento verdaderamente afortunado/a de tener a personas tan maravillosas en mi vida. Sus palabras y gestos me han recordado lo bendecido/a que soy por contar con su amistad y amor. Han hecho que este cumpleaños sea no solo un día de celebración, sino un día lleno de calidez y felicidad. Gracias por compartir conmigo sus buenos deseos y por ser parte de mi vida. Su apoyo y compañía significan más de lo que las palabras pueden expresar. Cada uno de ustedes ha contribuido a hacer de este día algo inolvidable, y por eso, estaré siempre agradecido/a. Espero poder devolver todo el cariño y la alegría que me han dado, y seguir compartiendo momentos maravillosos juntos. Con todo mi cariño y gratitud,',
            img1: Img3,
            img2: Img3_2,
            bg: 'bg-light text-dark',
            align: 'end',
            hide: true
          }]
        }
      },
      mounted(){
        window.addEventListener('scroll', this.handleScroll);
      },
      unmounted(){
        window.removeEventListener('scroll', this.handleScroll)
      },
      methods: {
        handleScroll () {
          for (let index = 0; index < this.cards.length; index++) {
            var top = document.getElementById('home-card-'+index).offsetTop
            if(document.documentElement.scrollTop >= (top-20)){
              if(this.cards[index]['hide']){
                this.cards[index]['hide'] = false
              }
            }
          }
        }
      }
    }
  </script>