<template>
    <div class="form-row align-items-stretch">
        <div :class="[info.bg, {'col-lg-4 py-4 d-flex flex-column':true}]" v-if="info.align=='start' && !mobile">
            <div class="text-start mb-3 mt-auto">
                <img :src="info.img2" :alt="info.title" class="img-fluid w-25">
            </div>
            <div class="px-5">
                <h2 v-text="info.title" class="fw-bold mb-3"></h2>
                <div v-html="info.descripcion" class="text-justify mb-5"></div>
                <div class="d-flex justify-content-between align-items-center">
                    <router-link :to="{name: info.url}" :class="[info.urlClass, {'btn':true}]" v-if="info.url">{{info.urlname}}</router-link>
                    <img :src="info.img3" :alt="'Firma '+info.title" class="img-fluid w-50" v-if="info.img3">
                </div>
            </div>
        </div>
        <div :class="{'col-lg-12':mobile, 'col-lg-8':!mobile}">
            <img :src="info.img1" :alt="info.title" class="w-100 h-100 img-background">
        </div>
        <div :class="[info.bg, {'col-lg-4 py-4 d-flex flex-column':true}]" v-if="info.align=='end' && !mobile">
            <div class="text-end mb-3 mt-auto">
                <img :src="info.img2" :alt="info.title" class="img-fluid w-25">
            </div>
            <div class="px-5">
                <h2 v-text="info.title" class="fw-bold mb-3"></h2>
                <div v-html="info.descripcion" class="text-justify mb-5"></div>
                <div class="d-flex justify-content-between align-items-center">
                    <router-link :to="{name: info.url}" :class="[info.urlClass, {'btn':true}]" v-if="info.url">{{info.urlname}}</router-link>
                    <img :src="info.img3" :alt="'Firma '+info.title" class="img-fluid w-25" v-if="info.img3">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['datos'],
    computed: {
        info(){
            return this.datos || {}
        },
        mobile(){
            return this.$store.getters.mobile
        }
    }
}
</script>